@import './../../styles/variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.wizard {
    display: flex;
    align-items: center;
}

.wizard-step {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    white-space: nowrap;

    &--active {
        .wizard-step__icon {
            background: $primary;
        }
        .wizard-step__content {
            color: $body-color;
        }
    }

    &--disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &--complete {
        .wizard-step__icon {
            background: $success;
        }
    }

    &__icon {
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background: $gray-300;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        > * {
            color: #fff;
        }
    }

    &__content {
        color: $text-muted;
    }

    + .wizard-step {
        margin-left: 20px;
    }
}

@media (max-width: breakpoint-max('md', $grid-breakpoints)) {
    .wizard {
        flex-wrap: wrap;
    }

    .wizard-step {
        flex: 0 1 50%;
        padding: 0.5rem;

        + .wizard-step {
            margin-left: 0;    
        }
    }
}

@media (max-width: breakpoint-max('xs', $grid-breakpoints)) {
    .wizard-step {
        flex-basis: 100%;
    }
}
