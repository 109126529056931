@import './../variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.float-grid-parent {
    margin: 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;

    &.float-grid-parent__static {    
        @media (min-width: breakpoint-min('sm', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'sm');
        }
        @media (min-width: breakpoint-min('md', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'md');
        }
        @media (min-width: breakpoint-min('lg', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'lg');
        }
        @media (min-width: breakpoint-min('xl', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'xl');
        }
    }
}

.float-col {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
}
