/* React Quill Overrides */
@import "~bootstrap/scss/mixins/_border-radius.scss";
@import "./../variables.scss";

.card .quill {
    display: flex;
    flex-direction: column;

    .ql-toolbar {
        // START Bootstrap Card Header
        padding: $card-spacer-y $card-spacer-x;
        margin-bottom: 0; // Removes the default margin-bottom of <hN>
        color: $card-cap-color;
        background-color: $card-cap-bg;
        border-bottom: $card-border-width solid $card-border-color;

        &:first-child {
            @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
        }

        + .list-group {
            .list-group-item:first-child {
                border-top: 0;
            }
        }
        // END Bootstrap Card Header

        flex: 0 0 auto;
        border-width: 0 0 1px 0;
        background-color: $gray-100;
    }

    .ql-container {
        font-family: inherit;
        font-size: inherit;
        
        flex: 1 1 auto;
        border: none;
        display: flex;
        flex-direction: column;

        .ql-editor {
            flex: 1 1 auto;
            color: $body-color;
            background: $white;
        }
    }

    .ql-snow .ql-stroke {
        stroke: $body-color;
    }
    .ql-snow .ql-fill {
        fill: $body-color;
    }
}

// Button SVG modifiers
 .quill .ql-snow {
     .ql-stroke {
         stroke: $gray-500;
     }

     .ql-fill {
         fill: $gray-500;
     }
 } 

 // Hover button SVG modifiers
 .quill .ql-snow.ql-toolbar button:hover {
     .ql-stroke {
         stroke: $primary;
     }
     .ql-fill {
         fill: $primary;
     }
 }

 // Active button SVG modifiers
 .quill .ql-snow.ql-toolbar button.ql-active {
     .ql-stroke {
         stroke: $primary;
     }
     .ql-fill {
         fill: $primary;
     }
 }

 // Focus button SVG modifiers
 .quill .ql-snow.ql-toolbar button:focus {
     outline: none;

     .ql-stroke {
         stroke: $primary;
     }
     .ql-fill {
         fill: $primary;
     }
 } 