@import '~@owczar/dashboard-style--airframe/scss/style';
@import '~react-image-lightbox/style.css';
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');


.ck-editor__editable_inline {
  max-height: 500px;
  overflow-y: 'auto';
  color: black;
}

.filter-label {
  display: block;
  padding-top: 4px;
}