@import "./../variables.scss";

.dropzone {
  background-color: #ffffff;
  border: 2px dashed $gray-300;
  padding: 5px;
  text-align: center;
  color: #000;
  
  &:not([aria-disabled="true"]) {
    cursor: pointer;
  }
  &:not([aria-disabled="true"]):hover,
  &:not([aria-disabled="true"]):active {
    border-color: $primary;
    background-color: rgba($primary, 0.1);
  }
}
