@import '../../../styles/variables';

div.header {
    background: none;
    border-bottom: none;
    cursor: pointer;
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}