@import "./bootstrap/variables";

// Sidebar Styling
// =============================================
$sidebar-default-width: 250px;
$sidebar-slim-width: 60px;
$sidebar-slim-submenu-width: 250px;
$sidebar-background: $white;
$sidebar-overlay-transition: 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
$sidebar-brand-color: $primary;
$sidebar-link-color: $primary;
$sidebar-link-color-hover: $primary;
$sidebar-link-color-muted: $gray-700;

// Sidebar Menu Styling
// =============================================
$sidebar-menu-color: $primary;
$sidebar-menu-highlight-bg: $primary;
$sidebar-menu-highlight-color: $black;

// Default Top Level
@mixin sidebar-menu-item {
    color: $body-color;       
}
@mixin sidebar-menu-item--active {
    background-color: transparent;
    color: $sidebar-menu-highlight-color;
}
@mixin sidebar-menu-item--hover {
    @include sidebar-menu-item--active;
}

// Default Submenus
@mixin sidebar-submenu-item {
    @include sidebar-menu-item;                 // Note: Inherit from top level
}
@mixin sidebar-submenu-item--active {
    @include sidebar-menu-item--active;         // Note: Inherit from top level
}
@mixin sidebar-submenu-item--hover {
    @include sidebar-submenu-item--active;
}

// Slim Top Level
@mixin sidebar-slim-menu-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
@mixin sidebar-slim-menu-item--active {}
@mixin sidebar-slim-menu-item--hover {
    @include sidebar-slim-menu-item--active;
}

// Slim Submenus
@mixin sidebar-slim-submenu-item {}             // Note: Keep as in Default
@mixin sidebar-slim-submenu-item--active {}
@mixin sidebar-slim-submenu-item--hover {
    @include sidebar-slim-submenu-item--active;
}

$sidebar-slim-submenu-bg: $white;
$sidebar-slim-submenu-border: 1px solid $gray-300;
$sidebar-slim-submenu-border-radius: 5px;
$sidebar-slim-submenu-box-shadow: none;

$sidebar-menu-item-padding: (
    top: 0.5rem,
    right: 1.5rem,
    bottom: 0.5rem,
    left: 1.5rem,
);
$sidebar-submenu-item-padding: (
    top: 0.5rem,
    right: 1.5rem,
    bottom: 0.5rem,
    left:  2.5rem,
);
$sidebar-slim-submenu-item-padding: (
    top: 0.5rem,
    right: 1rem,
    bottom: 0.5rem,
    left: 1.5rem,
);
$sidebar-submenu-nested-offset: 1rem;
$sidebar-submenu-nested-font-size: 12px;

// Navbars Styling
// =============================================
$navbar-height: 56px;
$navbar-border-color: $gray-400;
$navbar-background-color: $white;
$navbar-shadow: (
    border: 1px solid $gray-300,
    shadow: 0px -4px 10px 2px rgba($gray-400, 0.5),
);

/* Avatar Variants Definitions */
$avatars: (
    sm: (
        size: 20px,
        
        status: (
            offset-bottom: 3px,
            offset-right: 1px,
            background-size: 14px,
            status-size-ratio: 0.70,
        ),
        status-icon: (
            offset-bottom: 3px,
            offset-right: -2px,
            background-size: 25px,
            status-size-ratio: 0.78,
            status-icon-size-ratio: 0.39,
        ),
        
        badge-top: 1px,
        badge-right: 1px,
        badge-font-size: 9px
    ),
    md: (
        size: 30px,
        
        status: (
            offset-bottom: 5px,
            offset-right: -1px,
            background-size: 15px,
             status-size-ratio: 0.70,
        ),
        status-icon: (
            offset-bottom: 5px,
            offset-right: 1px,
            background-size: 25px,
            status-size-ratio: 0.78,
            status-icon-size-ratio: 0.36,
        ),

        badge-top: 3px,
        badge-right: 2px
    ),
    lg: (
        size: 60px,

        status: (
            offset-bottom: 8px,
            offset-right: 10px,
            background-size: 15px,
            status-size-ratio: 0.70,
        ),
        status-icon: (
            offset-bottom: 8px,
            offset-right: 10px,
            background-size: 25px,
            status-size-ratio: 0.78,
            status-icon-size-ratio: 0.36,
        ),
        
        badge-top: 5px,
        badge-right: 10px
    )
);

// All Colors Definitions (Add more colors in further parameters)
$dashboard-colors: map-merge(
    map-merge(
        $grays,
        $colors
    ),
    $theme-colors
);