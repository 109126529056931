/*@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";*/
@import './../variables.scss';

.react-bootstrap-table th.sortable {
  white-space: nowrap;
}

.react-bootstrap-table th,
.react-bootstrap-table td {
  vertical-align: middle !important;
}

.react-bootstrap-table .table:not(.table-bordered) tbody tr:last-child td {
  border-bottom: 1px solid $table-border-color;
}

.react-bootstrap-table-pagination {
  padding: 0 0.75rem;
  list-style-type: none;
}

.react-bootstrap-table-pagination-list .pagination {
  justify-content: flex-end;
}

.react-bootstrap-table .column-header {
  display: none;
}

// CUSTOM:
.custom-table {
  background-color: #ffffff;
  th,
  td {
    box-sizing: content-box;
    white-space: nowrap;
    * {
      white-space: nowrap;
    }
  }
}
.custom-tr td {
  padding: 0.4rem;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-bootstrap-table-pagination .table td th {
  padding: 0.4rem;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item a {
  display: block;
} 

.page-link {
  border-radius: 0.25rem;
  font-weight: 700;
}

.page-item {
  margin-left: 8px;
}

.page-item.active .page-link {
  background-color: #10bff2;
  border-color: #10bff2;
  color: #ffffff;
}

.search-icons {
  border-radius: 0.3em !important;
  background-color: rgba(63, 70, 81, 0.3);
  border-color: rgba(63, 70, 81, 0);
}

#import-excel input[type='file'] {
  display: none;
  opacity: 0;
}

.cell-button {
  background-color: rgba(248, 249, 250, 0.9);
  border-color: rgba(63, 70, 81, 0.1);
  color: #000000;
}
